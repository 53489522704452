import LoadCmp from '../LoadCmp';

export const ROUTERS = [
  // {
  //   path: '/dg/data-track',
  //   key: 'dgDataTrack',
  //   component: LoadCmp(() => import('../pages/dg/dataTrack')),
  //   module: 'dgDataTrack',
  // },
  // {
  //   path: '/dg/data-access',
  //   key: 'dgDataAccess',
  //   component: LoadCmp(() => import('../pages/dg/dataAccess')),
  //   module: 'dgDataAccess',
  // },
  // {
  //   path: '/dg/data-validate',
  //   key: 'dgDataValidate',
  //   component: LoadCmp(() => import('../pages/dg/dataValidate')),
  //   module: 'dgDataValidate',
  // },
  // {
  //   path: '/dg/event',
  //   key: 'dgEvent',
  //   component: LoadCmp(() => import('../pages/dg/metaEvent')),
  //   module: 'dgEvent',
  // },
  // {
  //   path: '/dg/event-attr',
  //   key: 'dgEventAttr',
  //   component: LoadCmp(() => import('../pages/dg/metaEventAttr')),
  //   module: 'dgEventAttr',
  // },
  // {
  //   path: '/dg/user-attr',
  //   key: 'dgUserAttr',
  //   component: LoadCmp(() => import('../pages/dg/metaUserAttr')),
  //   module: 'dgUserAttr',
  // },
  // {
  //   path: '/dg/session',
  //   key: 'dgSession',
  //   component: LoadCmp(() => import('../pages/dg/metaSession')),
  //   module: 'dgSession',
  // },
];

export const ROUTERS2 = [
  {
    path: '/home/index',
    key: 'home',
    component: LoadCmp(() => import('../pages/aws/home/index')),
    module: 'home',
  },
  {
    path: '/home/test',
    key: 'homeTest',
    component: LoadCmp(() => import('../pages/aws/home/homeTest')),
    module: 'homeTest',
  },
  // {
  //   path: '/test',
  //   key: 'Test',
  //   component: LoadCmp(() => import('../pages/dg/test/index')),
  //   module: 'homeTest',
  // },
  // {
  //   path: '/web',
  //   key: 'web',
  //   component: LoadCmp(() => import('../pages/dg/test/web')),
  //   module: 'homeTest',
  // },
  {
    path: '/login',
    key: 'login',
    component: LoadCmp(() => import('../pages/aws/login')),
    module: 'homeTest',
  },
  {
    path: '/detail/:id',
    key: 'detail',
    component: LoadCmp(() => import('../pages/aws/detail')),
    module: 'homeTest',
  },
  {
    path: '/uc',
    key: 'userCenter',
    component: LoadCmp(() => import('../pages/aws/userCenter')),
    module: 'homeTest',
  },
];
