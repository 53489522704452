/** @format */

import * as React from 'react';
import Loading from './Loading';
import { message, Button, Modal } from '@tencent/tea-component';

function timePromise(seconds: number) {
  return new Promise(resolve => setTimeout(resolve, seconds, true));
}

function retryLoad(loader: any, times: number) {
  if (times >= 3) {
    message.warning({ content: '资源加载失败!' });
    Modal.alert({
      type: 'warning',
      message: '资源加载失败',
      description: '资源加载失败，是否刷新浏览器?',
      buttons: [
        <Button type="primary" onClick={() => window.location.reload()}>
          确认
        </Button>,
        <Button onClick={() => window.location.reload()}>刷新页面</Button>,
      ],
    });

    return Promise.resolve({
      default: null,
    });
  }
  return loader().catch((e: any) => {
    console.error(e);
    return timePromise(1000).then(() => retryLoad(loader, times + 1));
  });
}

const LoadCmp = (loader: any) => {
  const COMPONENT = React.lazy(() => retryLoad(loader, 0));
  return function MyComponent(props: any) {
    return (
      <React.Suspense fallback={<Loading />}>
        <COMPONENT {...props} />
      </React.Suspense>
    );
  };
};

export default LoadCmp;
