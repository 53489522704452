
import * as store from '../store';
import { BASE_PATH } from '../utils/config';
// http://139.199.162.33
export const CONST_API_HOST = BASE_PATH || '';

export const API_HOST = {
  toString() {
    // console.log('================== AUTHORITY_API_HOST', store.app.microProp);
    if (store.app.microProp?.api) {
      return store.app.microProp?.api;
    }
    return CONST_API_HOST;
  },
};
