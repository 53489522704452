/**
 * 错误码
 */
export enum ErrorCode {
  LoginInvalid = 'LoginInvalid',
}


export const EVENT_GROUP_OPTIONS = [
  {
    text: '不分组',
    value: '',
  },
  {
    text: '平台',
    value: 'platforms',
  },
  {
    text: '是否预置',
    value: 'preset',
  },
];

export const EVENT_ATTR_GROUP_OPTIONS = [
  {
    text: '不分组',
    value: '',
  },
  {
    text: '数据类型',
    value: 'dataTypes',
  },
  {
    text: '是否预置',
    value: 'preset',
  },
  {
    text: '字典',
    value: 'mapInfo',
  },
];

export const EVENT_ATTR2_GROUP_OPTIONS = [
  {
    text: '不分组',
    value: '',
  },
  {
    text: '数据类型',
    value: 'dataTypes',
  },
  {
    text: '是否预置',
    value: 'preset',
  },
  {
    text: '字典',
    value: 'mapInfo',
  },
  {
    text: '脱敏',
    value: 'desensitize',
  },
];

export const BEYOND_EVENT_ATTR_GROUP_OPTIONS = [
  {
    text: '不分组',
    value: '',
  },
  {
    text: '平台',
    value: 'platforms',
  },
  {
    text: '是否预置',
    value: 'preset',
  },
];

export const USER_ATTR_GROUP_OPTIONS = [
  {
    text: '不分组',
    value: '',
  },
  {
    text: '是否预置',
    value: 'preset',
  },
  {
    text: '是否回数',
    value: 'returned',
  },
];

export const REG_PATTREN = {
  code: /^\$?[A-Za-z]\w*$/,
  // 字母、下划线和$
  char_underline_dollar: /^[a-zA-Z_\\$]+$/,
  // 数字、字母和下划线
  num_char_underline: /^[a-zA-Z0-9_]+$/,
  // 数字、字母、中文和下划线
  num_char_underline_zh: /^[a-zA-Z0-9_\u4e00-\u9fa5]+$/,
};


export const EnableFunction = {
  sub_project: 'sub_project',
};
