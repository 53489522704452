/** @format */

import moment from 'moment';

// 补全0
function add0(num: number) {
  return num < 10 ? `0${num}` : num;
}

export function formatTime(timestamp: any) {
  const date = new Date(timestamp * 1);
  const hours = date.getHours();
  const min = date.getMinutes();
  const sec = date.getSeconds();
  return `${hours}:${min}:${sec}`;
}

export function jsonCopy(data: any) {
  return JSON.parse(JSON.stringify(data));
}

export function formatDateTime(timestamp: any) {
  if (timestamp) {
    const date = new Date(timestamp * 1);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours();
    const min = date.getMinutes();
    const sec = date.getSeconds();
    return `${year}-${add0(month)}-${add0(day)} ${add0(hours)}:${add0(min)}:${add0(sec)}`;
    // return year + '-' + add0(month) + '-' + add0(day) + ' ' + add0(hours) + ':' + add0(min) + ':' + add0(sec);
  }
  return '0000-00-00 00:00:00';
}

export function getRanNum() {
  const result = [];
  let i = 0;
  for (i; i < 4; i++) {
    const ranNum = Math.ceil(Math.random() * 25);
    result.push(String.fromCharCode(65 + ranNum));
  }
  return result.join('');
}

export function formatDate(timestamp: any) {
  if (timestamp) {
    const date = new Date(timestamp * 1);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}-${add0(month)}-${add0(day)}`;
    // return year + '-' + add0(month) + '-' + add0(day);
  }
  return '0000-00-00';
}

export function plainArray(a: any) {
  return a.reduce((preValue: any, current: any) => {
    if (Array.isArray(current)) {
      return preValue.concat(plainArray(current));
    }
    return preValue.concat(current);
  }, []);
}

export function getTimeInfo(time: number) {
  const h = Math.floor(time / 3600);
  const m = Math.floor((time / 60) % 60);
  const s = Math.floor(time % 60);
  if (h > 0) {
    return `${h}h${m}m${s}s`;
    // return h + 'h' + m + 'm' + s + 's';
  }
  if (m > 0) {
    // return m + 'm' + s + 's';
    return `${m}m${s}s`;
  }
  if (s > 0) {
    // return s + 's';
    return `${s}s`;
  }
  return '0s';
  // return 0 + 's';
}

export const formatCompleteDate = (date: Date) => (date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : '');

export function safeJson(json: string, defaultValue = {}) {
  try {
    return JSON.parse(json);
  } catch (e) {
    console.error(e);
  }
  return defaultValue;
}

export function sleep(time = 300) {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, time);
  });
}

/**
 * 时间格式化 =》 0h 0m 0s
 * @param second
 */
export function time2Str(second: number) {
  let [hour, min, sec] = ['0', '0', '0'];
  if (second > 0) {
    hour = Math.floor(second / 3600).toFixed(0);
    min = Math.floor((second % 3600) / 60).toFixed(0);
    sec = Math.floor((second % 3600) % 60).toFixed(0);
  }
  return `${hour}h ${min}m ${sec}s`;
}

/**
 * 手机号校验
 * @param phone
 */
export function isPhoneNumber(phone: string) {
  if (!phone || phone.length !== 11) {
    return false;
  }
  return /^1[3-9][0-9]{9}/.test(phone);
}

/**
 * 邮箱校验
 */
export function isEmail(email: string) {
  // eslint-disable-next-line
    return /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/.test(email);
}

/**
 * 获取cookie
 * @param key [string]
 * @returns [string]
 */

export const getCookie = (key: string): string => {
  const cookies = document.cookie.replace(/ /g, '').split(';');
  if (!cookies) return '';
  let value = '';
  cookies.forEach((e) => {
    const [cKey, cValue] = e.split('=');
    if (cKey === key) value = cValue;
  });
  return value;
};
/**
 * 获取cookie
 * @param key [string]
 * @returns [string]
 */
export const setCookie = (key: string, value: string, opts: any = {}) => {
  const cookies = [];
  const exp = new Date();
  const { expires = 1, path = '/' } = opts;
  exp.setTime(exp.getTime() + expires * 86400000);
  cookies.push(`${key}=${encodeURIComponent(value)}`);
  cookies.push(`expires=${exp.toUTCString()}`);
  cookies.push(`path=${path}`);
  document.cookie = cookies.join(';');
};

/**
 * 千分位
 * @param num [string | number]
 * @returns [string]
 */
export const numberFormat = (num: string | number) => {
  const number = +num;
  const numStr = `${num}`;
  // 非数字型字符串之间返回
  if (isNaN(number)) return num;
  // 带有小数点
  if (numStr.indexOf('.') !== -1) {
    return number.toLocaleString();
  }
  const regex = /(\d)(?=(?:\d{3})+$)/g;
  return numStr.replace(regex, '$1,');
};

export function momentFormatDateTime(timeStr: string) {
  return timeStr ? moment(timeStr).format('YYYY-MM-DD HH:mm:ss') : '';;
}


export function downloadFile(url, type) {
  const link = document.createElement('a');
  link.style.display = 'none';
  link.href = url;
  link.setAttribute('download', `${type}-${moment().format('YYYY-MM-DD_HHmmss')}.xlsx`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export  function extractUrlsFromString(str) {
  const regex = /(https?:\/\/[^\s)]+)/g;
  let match;
  const urls = [];

  while ((match = regex.exec(str)) !== null) {
    urls.push(match[1]);
  }

  return urls;
};
