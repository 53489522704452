/** @format */

import { message } from '@tencent/tea-component';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { ErrorCode } from './enum';
import { ApiResponse } from './types';
import * as store from '../store';
// eslint-disable-next-line @typescript-eslint/no-require-imports
const settle = require('axios/lib/core/settle');

/**
 * mock数据适配器
 * @param config
 */
function mockAdapter(config: MyRequestConfig) {
  console.log(`-- mock -- ${config.url}`, config.mock);
  return new Promise<any>((resolve, reject) => {
    const response = {
      data: config.mock,
      status: '',
      statusText: '',
      headers: {},
      config,
      request: {},
    };
    settle(resolve, reject, response);
  });
}

const axiosClient = axios.create({
  baseURL: '',
  timeout: 300 * 1000,
  withCredentials: true,
});

/** ******************************** 请求拦截
 *
 */
/**
 * 请求拦截器
 * @param config 请求配置
 */
const requestInterceptor = (config: AxiosRequestConfig) => {
  // 必传appKey
  if (config.data) {
    if (Object.prototype.toString.call(config.data) === '[object FormData]') {
      // 是formdata类型
      config.data.set('subProject', store.app.subProject);
    } else {
      // appKey放到中间件读取赋值
      Object.assign(config.data, { subProject: store.app.subProject });
    }
  } else {
    config.data = {
      subProject: store.app.subProject,
    };
  }
  return config;
};
axiosClient.interceptors.request.use(requestInterceptor, error => Promise.reject(error));

/** ******************************** 响应拦截
 *
 */
/**
 * 请求拦截器
 * 处理返回码
 * @param response 返回结果
 */
const responseInterceptor = (response: AxiosResponse<ApiResponse<any>>) => {
  if (response?.config?.responseType === 'arraybuffer') {
    // arraybuffer一般是二进制文件，直接返回数据
    return response.data;
  }
  // @ts-ignore
  if (response?.data?.code === ErrorCode.LoginInvalid) {
    message.error({ content: '登录失败，请刷新重试' });
    throw new Error(`请求失败，URL：${response?.config?.url} ，原因：登录失败`);
  }
  if (response?.data?.ErrCode === 0) {
    // 成功，直接返回数据
    return response.data;
  }
  return response.data;
  /** ********* 下面开始处理失败情况 ********* **/
  // 鉴权失败
  // if (response?.data?.Msg === ErrorCode.LoginInvalid) {
  //   message.error({ content: '登录失败，请刷新重试' });
  //   throw new Error(`请求失败，URL：${response?.config?.url} ，原因：登录失败`);
  // }
  // // throw new Error(`请求失败，URL：${response?.config?.url} ，原因：${response?.data?.message}`);
  // throw new Error(response?.data?.Msg);
};
axiosClient.interceptors.response.use(responseInterceptor, error => Promise.reject(error));

export interface MyRequestConfig extends AxiosRequestConfig {
  mock?: any;
}

function axiosErrorHandler(reason: any) {
  // 请求有问题时抛异常，useRequest的onSuccess不执行，可以减少状态判断
  // throw new Error(`请求失败，URL：${reason?.config?.url} ，原因：${reason.message}`);
  throw new Error(reason.message);
}

export default <T extends any>(config: MyRequestConfig): Promise<T> => {

  if (config.mock) {
    config.adapter = mockAdapter;
  }
  return axiosClient(config).catch(axiosErrorHandler) as any;
};
