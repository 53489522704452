import { center } from '@antv/x6/lib/registry/node-anchor/bbox';
import { Layout, Menu, Row, Col, Card, Button, Checkbox, Link } from 'tdesign-react';
const { Header, Content, Footer } = Layout;

const CommonFoot = (props) => {
  const { title, hadTitle, subTitle, url, visible, closeFunc: changeVisible } = props;

  return (
    <Footer style={{ textAlign: 'center' }}>
      <p>Copyright @ 2013-2025 . All Rights Reserved</p>
      <p><Link href="https://beian.miit.gov.cn/#/Integrated/index">ICP备案/许可证号：粤ICP备19140641号</Link></p>
    </Footer>
  );
};
export default (CommonFoot);


