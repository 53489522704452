/** @format */
import './public-path';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.less';
import App from './App';
import '@tencent/tea-component/dist/tea.css';
import 'moment/locale/zh-cn';
import * as store from './store';

function render(props) {
  const { container, showMenu, prefix, api } = props;
  if (container) {
    const microProps = {
      showMenu,
      prefix,
      api,
    };
    store.app.microProp = microProps;
  }
  // ReactDOM.render(<App />, container ? container.querySelector('#root') : document.querySelector('#root'));

  const root = ReactDOM.createRoot(document.getElementById('root')); // 使用 createRoot 替代 ReactDOM.render
  root.render(<App />);
}

render({});

export async function bootstrap() {
  console.log('[react16] react app bootstraped');
}

export async function mount(props) {
  console.log('[react16] props from main framework', props);
  render(props);
}

export async function unmount(props) {
  const { container } = props;
  ReactDOM.unmountComponentAtNode(container ? container.querySelector('#root') : document.querySelector('#root'));
}
