/** @format */

// import { Layout } from '@tencent/tea-component';
import { Observer } from 'mobx-react';
import React from 'react';
import {
  BrowserRouter as Router,
  HashRouter,
  Route,
  Switch,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { getConfig, login } from './apis/common';
// import './App.less';
import { ROUTERS, ROUTERS2 } from './routes/routes';
import SideMenu from './routes/SideMenu';
import * as store from './store';
import { BASE_PATH, SHOW_SIDEBAR } from './utils/config';
// import Test from './pages/dg/test/index';
// import Web from './pages/dg/test/web';
import Login from './pages/aws/login';
import Detail from './pages/aws/detail';
import UserCenter from './pages/aws/userCenter';
import HomeHead from './pages/components/HomeHead';
import { useDictMap } from './hooks/useDictMap';

import { createBrowserHistory } from 'history';

import CommonFoot from './pages/components/CommonFoot';

import { Input, Layout as Layout2, Menu } from 'tdesign-react';
import {
  SearchIcon,
  NotificationFilledIcon,
  HomeIcon,
  ImageSearchIcon,
  CircleIcon,
} from 'tdesign-icons-react';
import { getCookie } from './utils/function';

const { Header, Content, Footer } = Layout2;
const { HeadMenu, MenuItem } = Menu;

// const { Body, Sider } = Layout;
// function AppContainer() {
//   const sidebarDisplay = store.app.microProp?.showMenu === undefined ? SHOW_SIDEBAR : store.app.microProp.showMenu;
//   return (
//     <Layout className="data_manage_main">
//       <Body>
//         {sidebarDisplay ? (
//           <Sider>
//             <SideMenu />
//           </Sider>
//         ) : null}
//         <Observer>
//           {() => {
//             const { subProject } = store.app;
//             console.log(subProject);
//             return (
//               <Switch>
//                 {ROUTERS.map((route: any) => {
//                   const { component: COMPONENT } = route;
//                   return <Route
// path={route.path}  key={`${route.key}_${subProject}`}
//                   render={props => <COMPONENT {...props} />} />;
//                 })}
//                 {/* <Navigate from="/" exact to="/dg/data-track" /> */}
//               </Switch>
//             );
//           }}
//         </Observer>
//       </Body>
//     </Layout>
//   );
// }

function AppContainer2() {
  return (
    <Layout2>
      {/* <Observer>


        {() => {
          const { subProject } = store.app;
          console.log(subProject);
          return ( */}

      <Switch>
        {ROUTERS2.map((route: any) => {
          const { component: COMPONENT } = route;
          return (
            <Route
              path={route.path}
              key={`${route.key}`}
              exact
              render={(props) => <COMPONENT {...props} />}
            />
          );
        })}
        <Redirect from="/" exact to="/home/index" />
      </Switch>
      {/* );
        }
          }
      </Observer> */}
      <CommonFoot />
    </Layout2>
  );
}

export class App extends React.Component {
  state = {
    // 应用是否就绪
    ready: false,
  };

  async componentDidMount() {
    // 请求全局配置数据
    this.init();
  }

  init = async () => {
    const configResp = await getConfig();
    console.log('configResp:', configResp);
    if (configResp?.ErrCode === 0 && configResp.Data) {
      const { dictMap } = configResp.Data;
      // store.app.setPageConfig(pageConfig);
      // 调用里面mock就可以了很简单
      store.app.setDictMap(dictMap);
      store.app2.init();
      const loginData = decodeURIComponent(getCookie('loginData'));

      const urlParams = new URL(window.location.href);
      const pathname = urlParams?.pathname;
      console.log('pathname:', pathname);
      const history = createBrowserHistory();

      console.log('login data:', loginData);
      if (loginData === '') {
        // relogin ****
        if (
          pathname !== '/login' &&
          pathname !== '/home/index' &&
          pathname !== '/'
        ) {
          // store.app2.dataMap.preRedirectUrl = pathname;
          // window.location.href = '/login';
          history.push('/login');
          history.go(0);
        }
      } else {
        const loginData2 = JSON.parse(loginData);
        const { id } = loginData2;
        console.log('login data id:', id);
        if (id === 0 && pathname !== '/login') {
          // relogin ****
          // store.app2.dataMap.preRedirectUrl = pathname;
          // window.location.href = '/login';
          history.push('/login');
          history.go(0);
        } else {
          store.app2.dataMap.loginData = loginData2;
        }
      }

      // const kqmap = store.app.dictMap.get('k1');// const kqmap = useDictMap('k1'); //
      // console.log('getdictmap:', kqmap);
      // store.app.setSubProjectList(subProjectList);
    }
    this.setState({ ready: true });
  };
  renderHome() {
    return (
      <Switch>
        <Route path="/" component={AppContainer2} />
      </Switch>
    );
  }

  render() {
    const microPrefix = store.app.microProp?.prefix;
    const basename = BASE_PATH;
    console.log('basename:', basename, microPrefix, store);
    return (
      <Router
        basename={window.__POWERED_BY_QIANKUN__ ? `/${microPrefix}` : basename}
      >
        {this.state.ready ? this.renderHome() : null}
      </Router>
    );
  }
}

export default App;
