/** @format */

import request from '../utils/axios';
import { API_HOST } from './config';
import { ApiResponse } from '../utils/types';

export function getConfig() {
  return request<ApiResponse>({
    url: `${API_HOST}/api/common/getConfig`,
    method: 'post',
    mock: {
      ErrCode: 0,
      Data: {
        dictMap: {
          k1: 'v1',
          k2: 'v2',
        },
      },
    },
  });
}
// mock记录：export function getConfig() {
//   return request<ApiResponse>({
//     url: `${API_HOST}/api/common/getConfig`,
//     method: 'post',
//     mock: {
//       ErrCode: 0,
//       Data: {
//         dictMap: {
//           k1: 'v1',
//           k2: 'v2',
//         },
//       },
//     },
//   });
// }

export function accessReferenceQuery(data) {
  return request<ApiResponse>({
    url: `${API_HOST}/api/access_reference/query`,
    method: 'post',
    data,
  });
}

export function dataStoreConfig(data) {
  return request<ApiResponse>({
    url: `${API_HOST}/api/data_store/config`,
    method: 'post',
    data,
  });
}

export function upload(data) {
  // <ApiResponse>加了这个就会限制返回类型
  return request<any>({
    url: `${API_HOST}/upload2cdn`,
    // url: 'https://anxindata.mtest.qq.com/cos/upload2cdn',
    method: 'post',
    data,
  });
}


export function uploadProcess(data, config) {
  // <ApiResponse>加了这个就会限制返回类型
  const { setUploadProgress } = config;
  return request<any>({
    url: `${API_HOST}/upload2cdn`,
    // url: 'https://anxindata.mtest.qq.com/cos/upload2cdn',
    method: 'post',
    data,
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setUploadProgress(percentCompleted);
    },
  });
}

export function register(data) {
  // <ApiResponse>加了这个就会限制返回类型
  return request<any>({
    url: `${API_HOST}/api/loginRegister`,
    method: 'post',
    data,
  });
}


export function login(data) {
  // <ApiResponse>加了这个就会限制返回类型
  return request<any>({
    url: `${API_HOST}/api/login`,
    method: 'post',
    data,
  });
}

export function loginOut(data) {
  // <ApiResponse>加了这个就会限制返回类型
  return request<any>({
    url: `${API_HOST}/api/out`,
    method: 'post',
    data,
  });
}

export function us(data) {
  // <ApiResponse>加了这个就会限制返回类型
  return request<any>({
    url: `${API_HOST}/api/us`,
    method: 'post',
    data,
  });
}

export function updateAttr(data, attrType) {
  // <ApiResponse>加了这个就会限制返回类型
  return request<any>({
    url: `${API_HOST}/api/${attrType}`,
    method: 'post',
    data,
  });
}

