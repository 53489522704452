/** @format */

import { action, computed, observable } from 'mobx';


export class App2 {
  // 以项目进行数据分析
  @observable
  dl = 1;
  @observable
  requestCache: Map<string, any> = new Map<string, any>();
  @observable
  locks: Map<string, Promise<any> | undefined | boolean> = new Map<string, Promise<any> | undefined | boolean>();
  @observable
  dictMap: Map<string, any> = new Map<string, any>();
  @observable
  subProject = '';
  @observable
  itemCardList = [];
  @observable
  pageConfig: any = {
    enableFunction: [],
  };
  @observable
  microProp: any = undefined;

  @observable
  dataMap: {
    itemCardList: Array<any>,
    loginData: { id: 0, tel: '', country: '', province: '',
      city: '', street: '', detail_pos: '',
      longitude: 0.1, latitude: 0.1, attr: '',
    },
    preRedirectUrl: string,

  };

  @action
  addDl=() => {
    this.dl += 1;
  };
  @computed
  get getDl() {
    return this.dl;
  }

  @action
  getLock(key: string): any {
    const lock = this.locks.get(key);
    if (!lock) {
      this.locks.set(key, true);
      return true;
    }
    return false;
  }

  @action
  releaseLock(key: string): any {
    this.locks.set(key, undefined);
  }

  @action
  setDictMap(data): any {
    Object.keys(data).forEach((key) => {
      this.dictMap.set(key, data[key]);
    });
  }
  init() {
    this.dataMap = {
      loginData: { id: 0, tel: '', country: '', province: '',
        city: '', street: '', detail_pos: '',
        longitude: 0.1, latitude: 0.1, attr: '',
      },
      itemCardList: Array<any>(),
      preRedirectUrl: '',
    };
  }
}

export default new App2();
