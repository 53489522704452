/** @format */

import { action, observable } from 'mobx';
import { getCookie, setCookie } from '../utils/function';

export type ModuleProps = 'data_overview' | 'data_analysis';

export class App {
  // 以项目进行数据分析
  @observable
  public = 1;
  @observable
  requestCache: Map<string, any> = new Map<string, any>();
  @observable
  locks: Map<string, Promise<any> | undefined | boolean> = new Map<string, Promise<any> | undefined | boolean>();
  @observable
  dictMap: Map<string, any> = new Map<string, any>();
  @observable
  subProject = '';
  @observable
  subProjectList = [];
  @observable
  pageConfig: any = {
    enableFunction: [],
  };
  @observable
  microProp: any = undefined;

  @action
  setRequestCache(key: string, data: any) {
    this.requestCache.set(key, data);
  }

  @action
  getRequestCache(key: string): any {
    return this.requestCache.get(key);
  }

  @action
  getLock(key: string): any {
    const lock = this.locks.get(key);
    if (!lock) {
      this.locks.set(key, true);
      return true;
    }
    return false;
  }

  @action
  releaseLock(key: string): any {
    this.locks.set(key, undefined);
  }

  @action
  setDictMap(data): any {
    Object.keys(data).forEach((key) => {
      this.dictMap.set(key, data[key]);
    });
  }

  @action
  setSubProject(subProject: string): any {
    if (!subProject) {
      return;
    }
    const project = this.subProjectList.find(v => v.value === subProject);
    if (project) {
      this.subProject = subProject;
      setCookie('sub_project', subProject, { expires: 30 });
    }
  }
  @action
  setSubProjectList(projects: any): any {
    const cookieSubProject = getCookie('sub_project');
    if (projects.length) {
      this.subProjectList = projects?.map(v => ({
        value: v.subProject,
        text: v.name,
      }));
      this.setSubProject(cookieSubProject || projects[0].subProject);
    } else {
      this.setSubProject(cookieSubProject);
    }
  }
  @action
  setPageConfig(config: any) {
    this.pageConfig = config;
  }
}

export default new App();
